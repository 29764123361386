import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import HowCarousel from "../components/HowCarousel"
import {Spring} from 'react-spring/renderprops'
import VisibilitySensor from "react-visibility-sensor";

import Circle from "../images/circle-how.svg";



const yellow = '#F5AA28';

const How = () => (
  <Layout>
    <SEO title="How?" />
    <Container className='green-b fluid' fluid>

      <Container>
      <div className='sections-top-gap'></div>

          <Row className='pt-5'>

            <Col xs={8} sm={8} md={8} lg={9} xl={9}>
              <VisibilitySensor>
                {({ isVisible }) => (
                  <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                    {({ opacity  }) => 
                      <div style={{ opacity }} className='how'>
                        <h4 className='yellow'>
                        How to make a sensory story
                        </h4>
                        </div>
                          }
                          </Spring>
                          )}
                </VisibilitySensor>
              </Col>

              <Col xs={4} sm={4} md={4} lg={3} xl={3} >

                <VisibilitySensor className='float-right'>
                          {({ isVisible }) => (
                            <Spring delay={100} to={{ opacity: isVisible ? 1 : 0 }}>
                              {({ opacity }) => 
                          <div>
                          <div className='circle' style={{ opacity}}>
                          <p className='position-absolute text-center circle-text skin'>Step by Step Guide</p>
                          </div>
                          </div>
                          }
                          </Spring>
                          )}
                </VisibilitySensor>
          
                </Col>

            </Row>
            
      

          <Row className='pt-5'>
              <Col>
              <HowCarousel/>
              </Col>
          </Row>
          </Container>

        </Container>

  </Layout>
)

export default How


            {/* <Link to="/">Go back to the homepage</Link> */}
