// import { Link } from "gatsby"
import React from "react"
import {Modal, Carousel, Row, Col, Container, Button} from 'react-bootstrap';
import SampleTemplate from "../components/SampleTemplate";


import How1 from "../images/how-1.svg";
import How2 from "../images/how-2.svg";
import How3 from "../images/how-3.svg";
import How4 from "../images/how-4.svg";
import How5 from "../images/how-5.svg";
import How6 from "../images/how-6.svg";



class HowCarousel extends React.Component {

  render() {

        return (
          <TemplateModal/>
        );

    }

}

// MODAL


function TemplateModal() {
  const [show, setShow] = React.useState(false);
//   this.state = {showModal1:true, showModal2:false}

  return (
    <>
{/* Carousel  */}

<Carousel indicators={false} className='' interval={10000000} className='green-b mb-10 mt-3'>

{/* ---------- 1 ----------  */}
<Carousel.Item className='text-center'>
    <Container >
        <Row
            style={{
            height: '100%',
            alignItems: 'center'
        }}
        className='mb-6'
            >

            <Col className='text-left' xs={2} sm={2} md={2} lg={2} xl={2}>
            <h4 className='carousel-number'>1.</h4>
            </Col>
            <Col xs={{ span: 7, offset: 1 }} sm={{ span: 7, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
              <How1 className='carousel-icon'/>

            </Col>
        </Row>
        <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={10} className='text-left mb-0'>
                <h1 className='pt-5'>
                    Choose your story
                </h1>
</Col>
                {/* <TemplateModal /> */}
                <Col xs={12} className='text-left'>

                 <p className='pt-4'>
                    Choose your story: perhaps your child’s favourite story or a popular children’s
                    book.
                </p>
            </Col>
        </Row>

    </Container>
</Carousel.Item>

{/* ---------- 2 ----------  */}
<Carousel.Item className='text-center'>
    <Container>
        <Row
            style={{
            height: '100%',
            alignItems: 'center'
        }}
            className='mb-6'>

            <Col className='text-left' xs={2} sm={2} md={2} lg={2} xl={2}>
            <h4 className='carousel-number'>2.</h4>
            </Col>
            <Col xs={{ span: 7, offset: 1 }} sm={{ span: 7, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
            <How2 className='carousel-icon'/>
            </Col>
        </Row>
        <Row>
            <Col xs={{ span: 7, offset: 2 }}>
        <div className='text-center'>
                <Button className='z-index' onClick={() => setShow(true)} >See Example</Button>
                </div>
                </Col>

        </Row>
        <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={10} className='text-left mb-0'>
        <h1 className='pt-5'>
                    Simplify it to 8—10 sentences
                </h1>
                </Col>
           
                <Col xs={12} className='text-left'>

                 <p className='pt-4'>
                    Simplify the story into 8-10 sentences. It’s ok if you do not say the exact
                    words of the story. Keep the sentences short and simple – stories don’t have to
                    be too long.
                </p>
                {/* <p className='red'>
                  Here is an example of .... 
                </p> */}
            </Col>
        </Row>

    </Container>
</Carousel.Item>

{/* ---------- 3 ----------  */}
<Carousel.Item className='text-center'>
    <Container>
        <Row
            style={{
            height: '100%',
            alignItems: 'center'
        }}
            className='mb-6'>
            <Col className='text-left' xs={2} sm={2} md={2} lg={2} xl={2}>
            <h4 className='carousel-number'>3.</h4>
            </Col>
            <Col xs={{ span: 7, offset: 1 }} sm={{ span: 7, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
            <How3 className='carousel-icon'/>
            </Col>
        </Row>
        <Row>
            <Col xs={{ span: 7, offset: 2 }}>
        <div className='text-center'>
                <Button className='z-index' onClick={() => setShow(true)} >See Example</Button>
                </div>
                </Col>

        </Row>

        <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={10} className='text-left mb-0'>
        <h1 className='pt-5'>
                    Choose an object or action that represents each sentence
                </h1>
</Col>
<Col xs={12} className='text-left'>
<p className='pt-4'>
                    For each sentence choose an object that represents an important element of that
                    sentence. Think about all the senses when you choose an object – touch, taste,
                    smell, sight and sound. Actions are great too! Give a hug, tickle, high-five or
                    clap. If you use the same object or action for another sentence that’s just
                    fine! Look around your home or classroom for objects – a pencil can be a wand
                    and a blanket can be a dark cave.
                </p>
            </Col>
        </Row>

    </Container>
</Carousel.Item>

{/* ---------- 4 ----------  */}
<Carousel.Item className='text-center'>
    <Container>
        <Row
            style={{
            height: '100%',
            alignItems: 'center'
        }}
            className='mb-6'>
            <Col className='text-left' xs={2} sm={2} md={2} lg={2} xl={2}>
                <h4 className='carousel-number'>4.</h4>
            </Col>
            <Col xs={{ span: 7, offset: 1 }} sm={{ span: 7, offset: 1 }} md={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }} xl={{ span: 6, offset: 1 }}>
            <How6 className='carousel-icon'/>
            </Col>
        </Row>
        <Row>
            <Col xs={{ span: 7, offset: 2 }}>
        <div className='text-center'>
                <Button className='z-index' onClick={() => setShow(true)} >See Example</Button>
                </div>
                </Col>

        </Row>

        <Row>
        <Col xs={10} sm={10} md={10} lg={8} xl={8} className='text-left mb-0'>
        <h1 className='pt-5'>
                    Practice actions to perform with each object
                </h1>
                </Col>
                
            
                <Col xs={12} className='text-left'>
                 <p className='pt-4'>
                    Use the template below as an aid and practice! Review your ten sentences and
                    experiment with the objects and with your voice. You can whisper, shout, even
                    make up character voices. Placing the items in a bag and revealing them sentence
                    by sentence creates suspense and excitement. Keep telling the story. Your child
                    will learn the actions and words, and will join in with the storytelling.
                </p>
            </Col>
        </Row>

    </Container>
</Carousel.Item>

</Carousel>



{/* Carousel End */}



{/* MODAL */}

      <Modal
        show={show}
        //  show={this.state.showModal1}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"    
        centered    
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>


        <Row className=''>
          <Col>
          
<SampleTemplate/>
          
          </Col>
       </Row>


        </Modal.Body>
      </Modal>
    </>
  );
}

export default HowCarousel;