import React from "react";
import {Table, Container, Row, Col} from "react-bootstrap";
// import Sari from "../components/SampleTemplate"

import TableIcon1 from "../images/table-icons-1.svg";
import TableIcon2 from "../images/table-icons-2.svg";
import TableIcon4 from "../images/table-icons-4.svg";

const SampleTemplate = () => (


<Container>

  <Row>
    <Col className='pl-0' xs={1} md={1} xl={1}></Col>
    <Col xs={5} md={5} xl={5}> <TableIcon1 className='table-icon' style={{ width: '35%' }}/></Col>
    <Col xs={3} md={3} xl={3}>  <TableIcon2 className='table-icon' style={{ width: '35%' }}/></Col>
    <Col xs={3} md={3} xl={3}>  <TableIcon4 className='table-icon' style={{ width: '35%' }}/></Col>
  </Row>

  <Row className='template-sample pt-2 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}> </Col>
    <Col xs={5} md={5} xl={5}> <h1>Story</h1></Col>
    <Col xs={3} md={3} xl={3}>  <h1>Object/<br/>Gesture</h1> </Col>
    <Col xs={3} md={3} xl={3}>  <h1>Action</h1></Col>
  </Row>

  <Row className='template-sample pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>1.</Col>
    <Col xs={5} md={5} xl={5}>
       <p className='red'>Example from Goodnight Moon by Margaret Wise Brown:</p>
       <p style={{fontStyle: 'italic'}}>
      
      "In the great green room
      <br/>There was a telephone
      <br/>And a red balloon
    And a picture of—"</p></Col>
    <Col xs={3} md={3} xl={3}>  <p className='red'>Red balloon</p> </Col>
    <Col xs={3} md={3} xl={3}>  <p style={{fontWeight: '400'}}>Blow up the red balloon and move it around the room. </p></Col>
  </Row>

  {/* <Row className='template-sample pt-2'>
    <Col className='pl-0' xs={1} md={1} xl={1}>1.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>
 */}
    
  <Row className='template-sample template-sample-dotted pt-4 pb-3'>
    <Col className='pl-0' xs={1} md={1} xl={1}>2.</Col>
    <Col xs={5} md={5} xl={5}> <p>Your next sentence here...</p></Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>


  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>3.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>


  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>4.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>


  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>5.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>


  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>6.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>

  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>7.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>

  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>8.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>

  <Row className='template-sample template-sample-dotted pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>9.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>

  <Row className=' pt-4 pb-4'>
    <Col className='pl-0' xs={1} md={1} xl={1}>10.</Col>
    <Col xs={5} md={5} xl={5}> </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
    <Col xs={3} md={3} xl={3}>  </Col>
  </Row>


</Container>














//   <Table>
//   <thead>
    
//   <tr>
//       <th></th>
//       <th><TableIcon1 className='table-icon' style={{ width: '35%' }}/></th>
//       <th><TableIcon2 className='table-icon' style={{ width: '35%' }}/></th>
//       <th></th>
//       <th><TableIcon4 className='table-icon' style={{ width: '35%' }}/></th>
//     </tr>

//     <tr className='line '>
//       <th></th>
//       <th > <h1>Story</h1> </th>
//       <th><h1>Object/<br/>Gesture</h1> </th>
//       <th></th>
//       <th ><h1>Action</h1></th>
//     </tr>

    
//   </thead>

// <br/>

//   <tbody>

//   <tr className='template-sample'>
//       <td >e.g.</td>
//       <td >Example from Goodnight Moon by Margaret Wise Brown:
// In the great green room
// There was a telephone
// And a red balloon
// And a picture of— </td>
//       <td className='red'>Red balloon
// </td>
//       <td ></td>
//       <td className='neu-reg'>Blow up the red balloon and move it around the room. </td>
//     </tr>


//     <tr className='template-sample'>
//       <td >1</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >2</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >3</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >4</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >5</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >6</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >7</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>
    
//     <tr className='template-sample'>
//       <td >8</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >9</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>

//     <tr className='template-sample'>
//       <td >10</td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//       <td ></td>
//     </tr>


//   </tbody>
  
// </Table>


  );

export default SampleTemplate;

